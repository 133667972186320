import './App.css';

import Card from './componet/Card.js';

function App() {
  return (
    <div>
     <Card/>
  
    </div>
  );
}

export default App;
